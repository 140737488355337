<template>
  <b-row>
    <b-col md="12" lg="12">
      <h1>{{ $t('dashboards.advanced-search') }}</h1>
      <div>
        <b-row>
          <b-col>
            <label
              >Please enter a search term (minimum 2 characters) and click the
              Search button to begin a search.</label
            >
          </b-col>
        </b-row>

        <b-row class="row">
          <b-col xl="3" lg="3" md="3">
            <b-form-group>
              <b-form-input
                type="text"
                size="lg"
                placeholder="Company ID"
                v-model.trim="companyId"
                v-on:keyup.enter="handleSearch()"
              >
              </b-form-input>
            </b-form-group>
          </b-col>

          <b-col xl="3" lg="3" md="3">
            <b-form-group>
              <b-form-input
                type="text"
                size="lg"
                placeholder="Application ID"
                v-model.trim="applicationId"
                v-on:keyup.enter="handleSearch()"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <b-form-group>
              <b-form-input
                type="text"
                size="lg"
                placeholder="Company Legal Name"
                v-model.trim="companyLegalName"
                v-on:keyup.enter="handleSearch()"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <b-form-group>
              <b-form-input
                type="text"
                size="lg"
                placeholder="DBA Company Name"
                v-model.trim="dBACompanyName"
                v-on:keyup.enter="handleSearch()"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="row">
          <b-col xl="3" lg="3" md="3">
            <b-form-group>
              <b-form-input
                type="text"
                size="lg"
                placeholder="Physical Address"
                v-model.trim="physicalAddress"
                v-on:keyup.enter="handleSearch()"
              >
              </b-form-input>
            </b-form-group>
          </b-col>

          <b-col xl="3" lg="3" md="3">
            <b-form-group>
              <b-form-input
                type="text"
                size="lg"
                placeholder="Phone Number"
                v-model.trim="phoneNumber"
                v-on:keyup.enter="handleSearch()"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <b-form-group>
              <b-form-input
                type="text"
                size="lg"
                placeholder="City"
                v-model.trim="city"
                v-on:keyup.enter="handleSearch()"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <b-form-group>
              <b-form-input
                type="text"
                size="lg"
                placeholder="State"
                v-model.trim="state"
                v-on:keyup.enter="handleSearch()"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="row">
          <b-col xl="3" lg="3" md="3">
            <b-form-group>
              <b-form-input
                type="text"
                size="lg"
                placeholder="Email Address"
                v-model.trim="emailAddress"
                v-on:keyup.enter="handleSearch()"
              >
              </b-form-input>
            </b-form-group>
          </b-col>

          <b-col xl="3" lg="3" md="3">
            <b-form-group>
              <b-form-input
                type="text"
                size="lg"
                placeholder="First Name"
                v-model.trim="firstName"
                v-on:keyup.enter="handleSearch()"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <b-form-group>
              <b-form-input
                type="text"
                size="lg"
                placeholder="Last Name"
                v-model.trim="lastName"
                v-on:keyup.enter="handleSearch()"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <b-form-group>
              <b-form-input
                type="text"
                size="lg"
                placeholder="Affiliate Name"
                v-model.trim="affiliateName"
                v-on:keyup.enter="handleSearch()"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col xl="4" lg="4" md="4">
            <b-button
              variant="primary"
              @click.self="handleSearch()"
              v-on:keyup.enter="handleSearch()"
              >Search</b-button
            >
          </b-col>

          <!-- <b-col
            class="text-right result-count"
            offset-xl="5"
            offset-lg="5"
            xl="1"
            lg="1"
            offset-md="4"
            md="2"
          >
            <label v-if="dataCount > 0">{{ dataCount }} Results</label>
          </b-col> -->
        </b-row>
      </div>
<datatable-heading
      ref="DatatableHeading"
      :hasTitleSection="false"
      :changePageSize="changePageSize"
      :searchChange="_.debounce(searchChange, 500)"
      :hasSearch="false"
      :from="from"
      :to="to"
      :total="total"
      :perPage="perPage"
      :tableFields="fields"
      @filterChange="filterChange">

    </datatable-heading>
      <b-overlay
        :show="gridLoading"
        variant="transparent"
        blur="5px"
        opacity="0.9"
      >
        <b-card class="mb-4">
          <vuetable
            ref="vuetable"
            track-by="companyID"
            :api-url="apiBase"
            :query-params="makeQueryParams"
            :per-page="perPage"
              :reactive-api-url="false"
              :fields="fields"
            :css="css.table"
            :sort-order="sortOrder"
              pagination-path
              @vuetable:pagination-data="onPaginationData"
              @vuetable:row-clicked="rowClicked"
              @vuetable:cell-rightclicked="rightClicked"
              @vuetable:load-error="handleLoadError"
              @vuetable:cell-clicked="cellclicked"
              @vuetable:loading="onVuetableLoading"
              @vuetable:loaded="onVuetableLoaded"
            :noDataTemplate="
              'Please enter a valid search term (company name, contact name, city, etc.) and click Search.'
            "
          >
          <template slot="applicationID" slot-scope="props">
                <div @contextmenu="rightClicked(props.rowData, '', $event)">
                  <ReadMoreList
                    :linesToShow="2"
                    separator=","
                    :listText="props.rowData.applicationID"
                  />
                </div>
              </template>
          </vuetable>
        </b-card>
        <vuetable-pagination-bootstrap
            class="mt-4"
            ref="pagination"
            @vuetable-pagination:change-page="onChangePage"
          />
      </b-overlay>
      <v-contextmenu ref="contextmenu">
        <v-contextmenu-item @click="onContextMenuAction('view')">
          <i class="iconsminds-3d-eyeglasses" />
          <span>View Company</span>
        </v-contextmenu-item>
      </v-contextmenu>
    </b-col>
  </b-row>
</template>

<script>
// :data-manager="dataManager":data-total="dataCount"
import CssTableConfig from '../../../constants/VuetableBootstrapconfig'
import CompanyMixin from '../../../mixins/CompanyMixin.vue'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import DatatableHeading from '../../../containers/datatable/DatatableHeading.vue'
import { BeaconapiUrl, CompanyContext } from '../../../constants/config'
import VuetablePaginationBootstrap from '../../../components/Common/VuetablePaginationBootstrap'
import ReadMoreList from '../../../components/Listing/ReadMoreList.vue'
import { mutations } from '../../../store/FilterMenu'

export default {
  props: ['companyID'],
  components: {
    vuetable: Vuetable,
    'vuetable-pagination-bootstrap': VuetablePaginationBootstrap,
    'datatable-heading': DatatableHeading,
    ReadMoreList: ReadMoreList
  },
  mixins: [CompanyMixin],
  validations: {},
  data () {
    var _idfield = 'companyID'
    return {
      fieldsToSearch: [
        'companyId',
        'applicationId',
        'companyLegalName',
        'dBACompanyName',
        'physicalAddress',
        'phoneNumber',
        'city',
        'state',
        'emailAddress',
        'firstName',
        'lastName',
        'affiliateName'
      ],
      apiBase: BeaconapiUrl + '/api/search/dynamic-search/table',
      json_data: [],
      sortOrder: [],
      dataCount: 0,
      isLoad: false,
      sort: '',
      page: 1,
      perPage: 25,
      filters: [],
      from: 0,
      to: 0,
      total: 0,
      lastPage: 0,
      items: [],
      selectedItems: [],
      idfield: _idfield,
      css: CssTableConfig,
      gridLoading: false,
      companyId: '',
      applicationId: '',
      companyLegalName: '',
      dBACompanyName: '',
      physicalAddress: '',
      phoneNumber: '',
      city: '',
      state: '',
      emailAddress: '',
      firstName: '',
      lastName: '',
      affiliateName: '',
      fields: [
        {
          name: _idfield,
          sortField: _idfield,
          title: 'Company ID',
          titleClass: '',
          dataClass: 'text-muted',
          width: '7%',
          filterType: 'number'
        },
        {
          name: '__slot:applicationID',
          sortField: 'applicationID',
          title: 'Application ID',
          titleClass: '',
          dataClass: 'text-muted',
          width: '15%',
          filterType: 'number'
        },
        {
          name: 'companyLegalName',
          sortField: 'companyLegalName',
          title: 'Company Legal Name',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%',
          filterType: 'text'
        },
        {
          name: 'dbaCompanyName',
          sortField: 'dbaCompanyName',
          title: 'DBA Company Name',
          titleClass: '',
          dataClass: 'text-muted',
          width: '20%',
          filterType: 'text'
        },
        {
          name: 'companyPhone',
          sortField: 'companyPhone',
          title: 'Company Phone',
          titleClass: '',
          dataClass: 'text-muted',
          width: '15%',
          filterType: 'text'
        },

        {
          name: 'contactInfo',
          sortField: 'contactInfo',
          title: 'Contact Info',
          titleClass: '',
          dataClass: 'text-muted',
          width: '20%',
          filterType: 'text'
        },
        {
          name: 'affiliateCompanyName',
          sortField: 'affiliateCompanyName',
          title: 'Affiliate Company Name',
          titleClass: '',
          dataClass: 'text-muted',
          width: '15%',
          filterType: 'text'
        },
        {
          name: 'physicalAddress',
          sortField: 'physicalAddress',
          title: 'Physical Address',
          titleClass: '',
          dataClass: 'text-muted',
          width: '15%',
          filterType: 'text'
        }
      ]
    }
  },
  methods: {
    makeQueryParams (sortOrder, currentPage, perPage) {
      this.selectedItems = []
      return {
        ...(sortOrder[0] && {
          sort: sortOrder[0]
            ? sortOrder[0].sortField + '|' + sortOrder[0].direction
            : ''
        }),
        page: currentPage,
        per_page: this.perPage,
        search: this.search,
        companyId: this.companyId && this.companyId,
        applicationId: this.applicationId,
        companyLegalName: this.companyLegalName && this.companyLegalName,
        dBACompanyName: this.dBACompanyName,
        physicalAddress: this.physicalAddress,
        phoneNumber: this.phoneNumber,
        city: this.city,
        state: this.state,
        emailAddress: this.emailAddress,
        firstName: this.firstName,
        lastName: this.lastName,
        affiliateName: this.affiliateName,
        filters: JSON.stringify(this.filters)
      }
    },
    onVuetableLoading () {
      this.gridLoading = true
    },
    onVuetableLoaded () {
      this.gridLoading = false
    },
    rowClicked () {},
    onPaginationData (paginationData) {
      this.from = paginationData.from
      this.to = paginationData.to
      this.total = paginationData.total
      this.lastPage = paginationData.last_page
      this.items = paginationData.data
      this.$refs.pagination.setPaginationData(paginationData)
      this.json_data = paginationData.data
    },
    rightClicked (dataItem, field, event) {
      event.preventDefault()
      this.rightClickedItem = dataItem
      this.$refs.contextmenu.show({ top: event.pageY, left: event.pageX })
    },
    handleLoadError (response) {
      this.setDangerMessage(response)
    },
    cellclicked (dataitem, field, event) {},
    refreshVueTable () {
      this.$refs.vuetable.refresh()
    },
    onContextMenuAction (action) {
      var rightClickedItem = this.rightClickedItem
      switch (action) {
        case 'view': {
          this.$router.push({ name: 'EditCompany', params: { companyID: rightClickedItem.companyID } })

          break
        }
        default:
          break
      }
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    changePageSize (perPage) {
      this.perPage = perPage
      this.refreshVueTable()
    },
    searchChange (val) {
      this.search = val
      this.refreshVueTable()
    },
    filterChange (filters) {
      this.filters = filters
      this.refreshVueTable()
    },
    dataManager (sortOrder, pagination) {
      const data = this.advancedSearchResults
      return {
        data
      }
    },
    handleSearch () {
      this.refreshVueTable()
    }
  },
  computed: {

  }
}
</script>
<style>
.result-count {
  margin-top: 25px;
}
.highlight-word {
  font-weight: bold;
}
</style>
